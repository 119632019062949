import https from '../utils/https'

// 会员列表接口
export function fetchMemberlist(params) {
  return https.fetchPost('/api/backstage/member/list', params)
}

// 会员信息接口
export function fetchMemberinfo(params) {
  return https.fetchGet('/api/backstage/member/info', params)
}

// 会员信息接口
export function changepayaccountloglist(params) {
  return https.fetchGet('/api/backstage/member/changepayaccountloglist', params)
}